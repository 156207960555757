import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import { Oceans } from "./Oceans";
import { RenderType } from "./RenderType";
import { SeaLevelOffset } from "./SeaLevelOffset";
import { Itterations } from "./Itterations";
import { AutoUpdate } from "./AutoUpdate";

export function MapSettings(props) {
  const sendMessage = props.messenger;

  const [oceansActive, setOceansActive] = useState(false);

  const setOceansToggle = (args) => {
    setOceansActive(args);
  };

  return (
    <Form.Group as={Row}>
      <Form.Label>Map Settings</Form.Label>
      <Col xs="2">
        <Oceans messenger={sendMessage} oceansSet={setOceansToggle} />
      </Col>
      <Col xs="5">
        {!oceansActive && <SeaLevelOffset messenger={sendMessage} value={18} />}
      </Col>
      <Col xs="5">
        <Itterations messenger={sendMessage} value={5} />
      </Col>
    </Form.Group>
  );
}
