import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export function Lacunarity(props) {
  const [value, setValue] = useState(props.value);
  const sendMessage = props.messenger;
  const type = props.type;
  const minP = -2;
  const maxP = 2.5;
  const stepP = 0.01;

  const minB = 1.5;
  const maxB = 3.5;
  const stepB = 0.01;

  const[thisType, setThisType] = useState(props.type);

  useEffect(()=>{
    setThisType(props.type);
  }, [props.type]);
  


  const updateLac = (e) => {
    let v1 = e.target.value;
    
    if (v1 > maxP) v1 = maxP;
    if (v1 < minP) v1 = minP;
    setValue(v1);
    sendLacunarity(v1);
  };

  const updateLacB = (e) => {
    let v1 = e.target.value;
    
    if (v1 > maxB) v1 = maxB;
    if (v1 < minB) v1 = minB;
    setValue(v1);
    sendLacunarity(v1);
  };

  function sendLacunarity(args) {
    sendMessage("JavascriptHook", "SetLacunarity", args);
  }

  function toggleCamera() {
    sendMessage("JavascriptHook", "ToggleCamera");
  }

  return (
    <React.Fragment>
   
        <Form.Group as={Row}>
          <Form.Label>Lacunarity</Form.Label>
          <Col xs="12">
          {type==0&&
            <RangeSlider
              variant="dark"
              min={minP}
              max={maxP}
              step={stepP}
              value={value}
              onChange={updateLac}
              size="sm"
              tooltip="on"
            />}
            {type==1&&
              <RangeSlider
                variant="dark"
                min={minB}
                max={maxB}
                step={stepB}
                value={value}
                onChange={updateLacB}
                size="sm"
                tooltip="on"
              />}
          </Col>
        </Form.Group>
     
    </React.Fragment>
  );
}
