import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export function Itterations(props) {
  const [value, setValue] = useState(props.value);
  const sendMessage = props.messenger;

  const updateItterations = (e) => {
    let v1 = e.target.value;
    if (v1 > 6) v1 = 6;
    if (v1 < 1) v1 = 1;
    v1 = Math.trunc(v1);
    setValue(v1);
    sendItterations(v1);
  };

  function sendItterations(args) {
    sendMessage("JavascriptHook", "SetItterations", args);
  }


  return (
    <React.Fragment>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>Itterations</Form.Label>
          <Col xs="12">
            <RangeSlider
              variant="dark"
              min={1}
              max={6}
              step={1}
              value={value}
              onChange={updateItterations}
              size="sm"
              tooltip="on"
            />
          </Col>
        </Form.Group>
      </Form>
    </React.Fragment>
  );
}