import React, { useState } from "react";
import { Form } from "react-bootstrap";


export function NoiseType(props){
    const[val, setVal] = useState(props.value);
    const updateType = props.typeUpdate;
    const sendMessage = props.messenger;
    const updateNoiseType = props.noiseTypeUpdate;

    

    const handleValChange = (e) => {
        setVal(e.target.value);
        updateType(e.target.value);
        sendNoiseType(e.target.value);
    }

    function sendNoiseType(args) {
        sendMessage("JavascriptHook", "SetNoiseType", args);
    }



    return(
        <React.Fragment>
        <Form.Label>Noise Type:</Form.Label>
        <Form.Select value={val} onChange={handleValChange} className="bg-dark text-white" size="sm" aria-label="Select Noise Type">
            <option value={0}>Perlin</option>
            <option value={1}>Billow</option>  
        </Form.Select>
        </React.Fragment>
    );
}