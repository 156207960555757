import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import CameraToggle from "./CameraToggle";
import { useState, useEffect } from "react";
import randomWord from "random-words";
import GenerateButton from "./GenerateButton";

function SeedInput(props) {
  const sendMessage = props.messenger;

  const [seed, setSeed] = useState("tacos");

  const updateseed = (e) => {
    setSeed(e.target.value);
  };

  function getRandomWord() {
    setSeed(randomWord() + " " + randomWord());
  }

  return (
    <div>
      <InputGroup size="sm" className="mb-3 seedcontainer">
        <CameraToggle messenger={sendMessage} />
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          classId="seedcontainer"
          style={{
            backgroundColor: "#202020",
            color: "wheat",
            borderColor: "#202020",
          }}
        >
          Seed: {"  "}
        </InputGroup.Text>

        <Form.Control
          onChange={updateseed}
          aria-label="seed"
          aria-describedby="inputGroup-sizing-sm"
          value={seed}
          className="formcontrol"
        />
        <Button
          bg="dark"
          variant="dark"
          onClick={getRandomWord}
          style={{ color: "wheat" }}
        >
          ♻Random
        </Button>
        <GenerateButton messenger={sendMessage} seed={seed} />
      </InputGroup>
    </div>
  );
}

export default SeedInput;
