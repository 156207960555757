import React from "react";
import { MakePlanet } from "./components/MakePlanet";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div bg="secondary" className="App">
      <MakePlanet />

      <br />
      <h8>More features coming soon!</h8>

      <br />

      <br />
      <br />
    </div>
  );
}

export default App;
