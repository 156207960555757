import React, { Fragment, Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";

export default class Footer extends Component {
  render() {
    return (
      
        <div className="footer" bg="dark" variant="dark" style={{color:"wheat"}}>
          <div style={{backgroundColor:"#212529",color:"wheat", textAlign:"center"}}>&copy; Copyright 2022 Richard Schmidt |
          <a
              href="mailto:"
          >richard.g.schmidt.jr@gmail.com</a> | <a href='https://github.com/RichardGSchmidt'>GitHub</a> | 
          </div>
        </div>
      
    );
  }
}