import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";

export function Oceans(props) {
    const sendMessage = props.messenger;
    const [oceans, setOceans] = useState(true);
    const setOceansToggle = props.oceansSet;

    const updateOceans = () => {
        setOceans(!oceans);
        sendOceans(oceans);
        setOceansToggle(oceans);
    }

    

    function sendOceans(args) {
        if (args == true){
        sendMessage("JavascriptHook", "ToggleOceans", 0)}
        else{
          sendMessage("JavascriptHook", "ToggleOceans", 1)
        };
      }

      return(
        <Form.Check type="checkbox" label={(oceans==true)? ("Oceans Enabled"):("Oceans Disabled")} 
        checked={oceans} onChange={updateOceans}/>
      );


}
