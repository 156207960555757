import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

function GenerateButton(props) {
  const sendMessage = props.messenger;
  const seed = props.seed;

  function generateMap() {
    SendSeed(seed);
    sendMessage("MapGenerator", "GenerateMap");
  }

  function SendSeed(args) {
    sendMessage("JavascriptHook", "SetSeed", args);
  }

  return (
    <div>
      <Button
        onClick={generateMap}
        style={{ color: "wheat" }}
        bg="dark"
        variant="dark"
      >
        Generate
      </Button>
    </div>
  );
}

export default GenerateButton;
