import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export function Octaves(props) {
  const [value, setValue] = useState(props.value);
  const sendMessage = props.messenger;

  const updateOct = (e) => {
    let v1 = e.target.value;
    if (v1 > 18) v1 = 18;
    if (v1 < 0) v1 = 0;
    v1 = Math.trunc(v1);
    setValue(v1);
    sendOctaves(v1);
  };

  function sendOctaves(args) {
    sendMessage("JavascriptHook", "SetOctaves", args);
  }

  function toggleCamera() {
    sendMessage("JavascriptHook", "ToggleCamera");
  }

  return (
    <React.Fragment>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>Octaves</Form.Label>
          <Col xs="12">
            <RangeSlider
              variant="dark"
              min={0}
              max={18}
              step={1}
              value={value}
              onChange={updateOct}
              size="sm"
              tooltip="on"
            />
          </Col>
          
        </Form.Group>
      </Form>
    </React.Fragment>
  );
}