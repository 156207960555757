import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";
import { Oceans } from "./Oceans";

export function RenderType(props) {
  const sendMessage = props.messenger;
  const [color, setColor] = useState(true);

  const updateRenderType = (e) => {
    setColor(!color);
    sendRenderType(color);
  };

  function sendRenderType(args) {
    if (args == true) {
      sendMessage("JavascriptHook", "SetRenderType", 1);
    } else {
      sendMessage("JavascriptHook", "SetRenderType", 0);
    }
  }

  return (
    <Form.Check
      type="switch"
      label={"Color/Greyscale " + color}
      checked={color}
      onChange={updateRenderType}
    />
  );
}
