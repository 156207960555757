import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row } from "react-bootstrap";

export function AutoUpdate(props) {
    const sendMessage = props.messenger;
    const [auto, setAuto] = useState(false);

    const updateAuto = () => {
        setAuto(!auto);
        sendAuto(auto);
    }

    function sendAuto(args) {
        if (args == true){
        sendMessage("JavascriptHook", "SetAutoUpdate", 0)}
        else{
          sendMessage("JavascriptHook", "SetAutoUpdate", 1)
        };
      }

      return(
        <Form.Check type="checkbox" label={"Auto-Update " + (auto)} 
        checked={auto} onChange={updateAuto}/>
      );


}