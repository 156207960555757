import "../App.css";
import "./MakePlanet.css";
import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Button from "react-bootstrap/Button";
import randomWord from "random-words";
import { isContentEditable } from "@testing-library/user-event/dist/utils";
import { Container, Row } from "react-bootstrap";
import SeedInput from "./SeedInput";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CameraToggle from "./CameraToggle";
import { NoiseFunction } from "./NoiseFunctions/NoiseFunction";
import { MapSettings } from "./MapSettings/MapSettings";

export function MakePlanet() {
  const [useAuto, setAuto] = useState(true);
  const {
    unityProvider,
    sendMessage,
    isLoaded,
    requestFullscreen,
    loadingProgression,
  } = useUnityContext({
    loaderUrl: "build/make-planet.loader.js",
    dataUrl: "build/make-planet.data",
    frameworkUrl: "build/make-planet.framework.js",
    codeUrl: "build/make-planet.wasm",
  });

  const [addOptions, setAddOptions] = useState(false);

  function handleAdditionalOptions() {
    setAddOptions(!addOptions);
  }

  const [gamepointers, setPointers] = useState(false);

  const enablePointers = (e) => {
    setPointers(true);
  };

  const togglePointer = (e) => {
    setPointers(!gamepointers);
  };

  const disablePointers = (e) => {
    setPointers(false);
  };

  const loadingPercentage = Math.round(loadingProgression * 100);

  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  return (
    <Container className="MakePlanet">
      <row>
        {isLoaded === false && (
          // We'll conditionally render the loading overlay if the Unity
          // Application is not loaded.
          <div className="loading-overlay">
            <p>Loading... ({loadingPercentage}%)</p>

            <p>Please be patient, the 90 is a lie.</p>
            <p>If error codes pop up, close your browser tabs and reload.</p>
          </div>
        )}

        <br />

        <Unity
          unityProvider={unityProvider}
          tabIndex={1}
          className={gamepointers ? "gamewithpointers" : "game col-12"}
        />
      </row>

      <row>
        <SeedInput messenger={sendMessage} />
        <Button variant="dark" size="sm" onClick={handleAdditionalOptions}>Additional Options</Button>
        {addOptions === true && <div><hr/><MapSettings messenger={sendMessage} /><hr/><NoiseFunction messenger={sendMessage} /> </div>}

      </row>
      <br></br>
      <hr />
    </Container>
  );
}
