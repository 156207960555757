import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { Lacunarity } from "./Lacunarity";
import { Frequency } from "./Frequency";
import { NoiseType } from "./NoiseType";
import { Persistence } from "./Persistence";
import { Octaves } from "./Octaves";

export function NoiseFunction(props) {
  const sendMessage = props.messenger;
  const [lac, setLac] = useState(2.2);
  const [type, setType] = useState(0);


  const updateType = (args) => {
    setType(args);
    updateNoiseType(args);
  };

  function updateNoiseType(args) {
    sendMessage("JavascriptHook", "SetNoiseType", args);
  }

  function toggleCamera() {
    sendMessage("JavascriptHook", "ToggleCamera");
  }

  return (
    <Container>
      <Form.Label>Noise Settings</Form.Label>
      <Row>
        <Col xs="6" md="3">
          <NoiseType
            value={type}
            typeUpdate={updateType}
            noiseTypeUpdate={updateNoiseType}
          />
        </Col>
        <Col xs="3" md="2">
          <Lacunarity messenger={sendMessage} value={lac} type={type} />
        </Col>
        <Col xs="3" md="2">
          <Frequency messenger={sendMessage} value={1.0} type={type} />
        </Col>
        <Col xs="3" md="2">
          <Persistence messenger={sendMessage} value={0.5} type={type} />
        </Col>
        <Col xs="3" md="2">
          <Octaves messenger={sendMessage} value={7} type={type} />
        </Col>
        <Col md="1">
          
        </Col>
      </Row>
    </Container>
  );
}
