import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import RangeSlider from "react-bootstrap-range-slider";
import { Col, Container, Row } from "react-bootstrap";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

export function Persistence(props) {
  const [value, setValue] = useState(props.value);
  const sendMessage = props.messenger;
  const type = props.type;
  const minP = -1;
  const maxP = 1;
  const minB = 0;
  const maxB = 1;

  const updatePerP = (e) => {
    let v1 = e.target.value;
    if (v1 == '.') v1=0.;
    if (v1 > maxP) v1 = maxP;
    if (v1 < minP) v1 = minP;
    setValue(v1);
    sendPers(v1);
  };

  const updatePerB = (e) => {
    let v1 = e.target.value;
    if (v1 > maxB) v1 = maxB;
    if (v1 < minB) v1 = minB;
    setValue(v1);
    sendPers(v1);
  };

  function sendPers(args) {
    sendMessage("JavascriptHook", "SetPers", args);
  }

  function toggleCamera() {
    sendMessage("JavascriptHook", "ToggleCamera");
  }

  return (
    <React.Fragment>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>Persistence</Form.Label>
          {type==0&&
            <React.Fragment>
          <Col xs="12">
            <RangeSlider
              variant="dark"
              min={minP}
              max={maxP}
              step={0.01}
              value={value}
              onChange={updatePerP}
              size="sm"
              tooltip="on"
            />
          </Col>
          </React.Fragment>
          }

          {type==1&&
            <React.Fragment>
          <Col xs="12">
            <RangeSlider
              variant="dark"
              min={minB}
              max={maxB}
              step={0.01}
              value={value}
              onChange={updatePerB}
              size="sm"
              tooltip="on"
            />
          </Col>
          </React.Fragment>
          }
        </Form.Group>
      </Form>
    </React.Fragment>
  );
}