import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BootstrapNavbar.css';

function BootstrapNavbar() {
  return (
    <Navbar  className='BootstrapNavbar' bg="dark" variant="dark" expand="lg-12">
      <Container>

        <Navbar.Brand href="" style={{color:"wheat"}}>
            
            <img
              alt="dot net logo"
              src="./dotnet.jpg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />

            <img
                alt="react logo"
                src="./logo192.png"
                width="30"
                height="30"
            />
            <img
                alt="react logo"
                src="./unitylogo.jpg"
                width="30"
                height="30"
            />
            <img
                alt="webgl logo"
                src="./WebGL_Logo.svg.png"
                width="60"
                height= "25"
            />
            
            {' '}
            MakePlanet
            
          </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="https://github.com/RichardGSchmidt">Github</Nav.Link>
            <Nav.Link href="https://make-planet.nyc3.digitaloceanspaces.com/resume.pdf">Resume</Nav.Link>
            <Nav.Link href="mailto: richard.g.schmidt.jr@gmail.com">E-mail</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BootstrapNavbar;