import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";

function CameraToggle(props) {
  const messenger = props.messenger;
  function toggleCamera() {
    messenger("JavascriptHook", "ToggleCamera");
  }

  return (
    <div>
      <Button id="cam" bg="dark" variant="dark" onClick={toggleCamera}>
        📹CAM
      </Button>
    </div>
  );
}

export default CameraToggle;
